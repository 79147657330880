<style scoped>
.file:hover {
	text-decoration: underline;
	color: blue;
}
</style>
<template>
	<v-list-item>
		<v-list-item-icon>
			<v-icon color="grey">{{ icon }}</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title class="file"
				><span class="font-weight-light">{{
					name
				}}</span></v-list-item-title
			>
		</v-list-item-content>
		<v-list-item-action>
			<v-row>
				<v-btn small icon color="grey" @click="deleteFile"
					><v-icon>mdi-trash-can</v-icon></v-btn
				>
				<v-btn small icon color="success" @click="downloadFile"
					><v-icon>mdi-download</v-icon></v-btn
				>
			</v-row>
		</v-list-item-action>
	</v-list-item>
</template>
<script>
// import Vuex from "vuex";
import {
	getFileFromStorage,
	deleteFileFromStorage,
} from "../../assets/storageFunctions.js";
export default {
	name: "FileListItem",
	props: {
		item: { type: Object },
	},
	data: () => {
		return {
			items: [],
			icons: {
				xlsx: "mdi-file-excel-box",
				xls: "mdi-file-excel-box",
				pdf: "mdi-file-pdf-box",
				jpg: "mdi-file-jpg-box",
				png: "mdi-file-png-box",
				doc: "mdi-file-word-box",
				docx: "mdi-file-word-box",
			},
		};
	},
	computed: {
		path() {
			return this.item.fullPath;
		},
		name() {
			return this.item.name;
		},
		icon() {
			let ext = this.name.split(".")[1];
			let icon = this.icons[ext];
			return icon || "mdi-file";
		},
	},
	methods: {
		deleteFile() {
			const self = this;
			deleteFileFromStorage(self.path).then(() => {
				this.$emit("deleted", self.item);
			});
		},
		downloadFile() {
			const self = this;
			getFileFromStorage(self.path).then((url) => {
				window.open(url)
			});
		},
	},
};
</script>
